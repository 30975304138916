<template>
  <div
    v-tooltip="{
      content: tooltip.title,
      placement: tooltip.placement,
      delay: { show: 800, },
      disabled: !tooltip.title,
    }"
    class="timeline-label"
    :class="statusClasses"
  >
    <!-- @slot Emplacement pour une icône. -->
    <slot name="icon" />
    <component
      :is="icon"
      v-if="status !== 'awaiting'
        && (!counterLabel || status !== 'warning')"
    />
    <span
      class="label"
      :class="[
        small ? 'text-small' : 'text-regular',
        { icon: icon || $slots.icon, },
      ]"
    >
      {{ label }}
    </span>
    <TimelineLabel
      v-if="counterLabel"
      class="counter-label"
      :label="counterLabel"
      :status="status"
      :small="small"
    >
      <template #icon>
        <component :is="counterIcon" />
      </template>
    </TimelineLabel>
  </div>
</template>

<script>
import { UilSyncExclamation, UilTruck, UilBan } from "@iconscout/vue-unicons";
import { UisCheckCircle, UisExclamationOctagon } from "@iconscout/vue-unicons-solid";

/**
 * Labels utilisés pour le composant **Timeline**.
 */
export default {
  name: "TimelineLabel",
  props: {
    /**
     * Nom de l'étape.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * État de l'étape.
     */
    status: {
      type: String,
      validator: (value) => ["awaiting", "valid", "warning", "error", "expire", "transferee"].includes(value),
      default: "awaiting",
    },
    /**
     * Réduit la taille du composant.
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * Label du compartiment de droite.
     */
    counterLabel: {
      type: String,
      default: undefined,
    },
    /**
     * Informations liées à un éventuel tooltip.
     */
    tooltip: {
      type: Object,
      default: () => ({ title: "", position: "bottom" }),
    },
  },
  computed: {
    statusClasses() {
      const classes = [];
      if (this.small || this.$parent.small) {
        classes.push("small");
      }
      if (this.status !== "none") {
        classes.push(this.status);
      }
      if (this.counterLabel) {
        classes.push("with-counter");
      }
      return classes;
    },
    icon() {
      let icon = null;
      if (!this.$slots.icon) {
        switch (this.status) {
          case "valid":
            icon = UisCheckCircle;
            break;
          case "warning":
            icon = UilSyncExclamation;
            break;
          case "error":
            icon = UisExclamationOctagon;
            break;
          case "expire":
            icon = UilBan;
            break;
          default:
            icon = null;
            break;
        }
      }
      return icon;
    },
    counterIcon() {
      let icon = null;
      switch (this.status) {
        case "warning":
          icon = UilSyncExclamation;
          break;
        default:
          icon = UilTruck;
          break;
      }
      return icon;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/timeline/timeline_label.scss";
</style>
