var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.tooltip.title,
    placement: _vm.tooltip.placement,
    delay: { show: 800, },
    disabled: !_vm.tooltip.title,
  }),expression:"{\n    content: tooltip.title,\n    placement: tooltip.placement,\n    delay: { show: 800, },\n    disabled: !tooltip.title,\n  }"}],staticClass:"timeline-label",class:_vm.statusClasses},[_vm._t("icon"),(_vm.status !== 'awaiting'
      && (!_vm.counterLabel || _vm.status !== 'warning'))?_c(_vm.icon,{tag:"component"}):_vm._e(),_c('span',{staticClass:"label",class:[
      _vm.small ? 'text-small' : 'text-regular',
      { icon: _vm.icon || _vm.$slots.icon, },
    ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.counterLabel)?_c('TimelineLabel',{staticClass:"counter-label",attrs:{"label":_vm.counterLabel,"status":_vm.status,"small":_vm.small},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(_vm.counterIcon,{tag:"component"})]},proxy:true}],null,false,971508593)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }